import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";

const SEO = ({ title, description, img }) => {
  const { pathname } = useLocation();
  const { site, ogImg } = useStaticQuery(query);
  const { defaultTitle, defaultDescription, siteUrl } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
    baseUrl: siteUrl,
    image: img || ogImg.childImageSharp.fluid.src,
  };

  return (
    <>
      <Helmet title={seo.title} titleTemplate={`%s | ` + defaultTitle}>
        {seo.url && <meta property="og:url" content={seo.url} />}

        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.title && <meta property="twitter:title" content={seo.title} />}

        {seo.description && (
          <meta name="description" content={seo.description} />
        )}
        {seo.description && (
          <meta name="twitter:description" content={seo.description} />
        )}
        {seo.description && (
          <meta name="og:description" content={seo.description} />
        )}

        {seo.image && (
          <meta property="twitter:img:src" content={seo.baseUrl + seo.image} />
        )}
        {seo.image && (
          <meta property="og:image" content={seo.baseUrl + seo.image} />
        )}

        <link rel="icon" type="image/png" href="/favicon.ico" />
      </Helmet>
    </>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

SEO.defaultProps = {
  title: null,
  description: null,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        titleExtension
        siteUrl: url
      }
    }
    ogImg: file(relativePath: { eq: "index.jpg" }) {
      childImageSharp {
        fluid(quality: 60, maxWidth: 1000) {
          src
        }
      }
    }
  }
`;
