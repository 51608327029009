import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import constants from "../../utils/constants";
import Divider from "../common/divider";

export default () => {
  const data = useStaticQuery(graphql`
    query Footer {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            src
          }
        }
      }
      moto: file(relativePath: { eq: "footer-moto.png" }) {
        childImageSharp {
          fluid(maxHeight: 50) {
            src
          }
        }
      }
    }
  `);

  const logo = data.logo.childImageSharp.fluid.src;
  const moto = data.moto.childImageSharp.fluid.src;

  return (
    <>
      <section id="footer">
        <footer>
          <div className="upper-footer py-5">
            <Container className="text-center">
              <Row className="d-flex justify-content-center text-left">
                <Col lg={4} className="my-3">
                  <h4 className="text-white">За нас</h4>
                  <Divider addClassName="gold mb-3" align="start" />
                  <p>{constants.FOOTER.TEXT}</p>
                  <Link to="/za-nas">Прочети повече</Link>
                  <br />
                  <br />
                  <Image fluid src={logo} alt="Ключарите" className="mr-5" />
                  {/*<a*/}
                  {/*  href={constants.FOOTER.FACEBOOK}*/}
                  {/*  target="_blank"*/}
                  {/*  rel="noreferrer"*/}
                  {/*>*/}
                  {/*  <svg*/}
                  {/*    className="gold"*/}
                  {/*    xmlns="http://www.w3.org/2000/svg"*/}
                  {/*    width="35"*/}
                  {/*    height="35"*/}
                  {/*    viewBox="0 0 24 24"*/}
                  {/*  >*/}
                  {/*    <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />*/}
                  {/*  </svg>*/}
                  {/*</a>*/}
                </Col>

                <Col lg={3} className="my-3">
                  <h4 className="text-white">Бързи връзки</h4>
                  <Divider addClassName="gold mb-3" align="start" />
                  {constants.FOOTER.LINKS.map((route, index) => (
                    <div className="mb-2" key={index}>
                      <Link to={route.to} className="gold-link">
                        {route.text}
                      </Link>
                    </div>
                  ))}
                </Col>

                <Col lg={5} className="my-3 text-left">
                  <h4 className="text-white">Контакти</h4>
                  <Divider addClassName="gold mb-3" align="start" />

                  <div className="d-flex mb-4">
                    <div className="icon-wrapper">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 16 16"
                        className="bi bi-telephone-fill gold"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z"
                        />
                      </svg>
                    </div>

                    <div class="d-flex flex-column">
                      <h6 className="mb-3 text-white">Телефон за връзка</h6>
                      <a
                        href={`tel:${constants.TELEPHONE}`}
                        className="text-gold"
                      >
                        {constants.TELEPHONE}
                      </a>
                      <a
                        href={`tel:${constants.TELEPHONE_ALT_1}`}
                        className="text-gold"
                      >
                        {constants.TELEPHONE_ALT_1}
                      </a>
                    </div>
                  </div>

                  <div className="d-flex mb-4">
                    <div className="icon-wrapper">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 16 16"
                        className="bi bi-geo-fill gold"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"
                        />
                      </svg>
                    </div>

                    <div>
                      <h6 className="mb-3 text-white">Адрес</h6>
                      <a
                        href={`${constants.GOOGLE_MAPS_LINK}`}
                        className="text-gold"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {constants.ADDRESS}
                      </a>
                    </div>
                  </div>

                  <div className="d-flex mb-4">
                    <div className="icon-wrapper">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 16 16"
                        className="bi bi-clock gold"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm8-7A8 8 0 1 1 0 8a8 8 0 0 1 16 0z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"
                        />
                      </svg>
                    </div>

                    <div>
                      <h6 className="mb-3 text-white">Работно време</h6>

                      <div>
                        <p>
                          <b>Понеделник-Петък:</b> 09:00ч. - 18:00ч.
                        </p>
                        <p>
                          <b>Събота:</b> 10:00ч. - 13:00ч. <span class="text-nowrap"><b>Неделя:</b> почивен</span>
                        </p>
                        <p className="text-gold">
                          <b>Работно време при аварийни случаи: 24/7</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} className="d-flex justify-content-center my-2">
                  <Image fluid src={moto} alt="Locksmith you can trust" />
                </Col>
              </Row>
            </Container>
          </div>

          <div className="arrowed-section">
            <div className="lower-footer">
              <Container>
                <p className="text-center mb-0 dark-bg">
                  © {constants.FOOTER.TITLE} {new Date().getFullYear()} |{" "}
                  <a
                    href={`${constants.GOOGLE_MAPS_LINK}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {constants.ADDRESS}
                  </a>{" "}
                  |{" "}
                  <a href={`tel:${constants.TELEPHONE}`}>
                    {constants.TELEPHONE}
                  </a>{", "}
                  <a href={`tel:${constants.TELEPHONE_ALT_1}`}>
                    {constants.TELEPHONE_ALT_1}
                  </a>{" "}
                </p>
              </Container>
            </div>
          </div>
        </footer>
      </section>
    </>
  );
};
