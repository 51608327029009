import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ align }) => align};
`;

const Divider = ({ addClassName, align }) => {
  return (
    <Wrapper align={align}>
      <div className={`divider ${addClassName}`}></div>
    </Wrapper>
  );
};

export default Divider;

Divider.defaultProps = {
  align: "center",
};

Divider.propTypes = {
  align: PropTypes.string,
};
