export default {
  META: {
    TITLE: "Ключарите, на които можеш да се довериш",
    DESCRIPTION:
      "24/7 Ключарски услуги за гр. София. Денонощен и Авариен ключар. Реагираме до 30 мин. Най-съвременна техника. Обади се на: 0879 881 893",
  },

  TITLE: "Loksmit Ekspert",
  TELEPHONE: "+359 876 553 305",
  TELEPHONE_ALT_1: "+359 876 087 682",
  GOOGLE_MAPS_LINK: "https://maps.app.goo.gl/j8L7GAsXpqduWRx96",
  MAP_IFRAME_LINK:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2933.1215504530705!2d23.371604100000003!3d42.679967500000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa85dd68461ed9%3A0x67d7039d4dbdb193!2z0LYu0LouINCl0YDQuNGB0YLQviDQodC80LjRgNC90LXQvdGB0LrQuCwg0YPQuy4g4oCe0JjQstCw0L0g0JTQuNC80LjRgtGA0L7Qsi3QmtGD0LrQu9Cw0YLQsOKAnCA0LCAxNTc0INCh0L7RhNC40Y8!5e0!3m2!1sbg!2sbg!4v1726429462958!5m2!1sbg!2sbg",
  ADDRESS: "кв. Слатина, ул. Иван Димитров-Куклата 4",
  OPENING_HOURS: "24/7",
  ROUTES: [
    {
      to: "/",
      text: "Начало",
    },
    {
      to: "/bitovo-klucharski-uslugi",
      text: "Битово-ключарски услуги",
    },
    {
      to: "/avtoklucharski-uslugi",
      text: "Автоключарски услуги",
    },
    {
      to: "/za-nas",
      text: "За нас",
    },
    {
      to: "/kontakti",
      text: "Контакти",
    },
  ],

  FOOTER: {
    TITLE: "\"Локсмит Експерт ООД\"",
    TEXT:
      '\"Локсмит Експерт ООД\" е компания, насочена изцяло към предлагането на ключарски и автоключарски услуги, както и едни от най-добрите съвременни заключващи устройства.',
    LINKS: [
      {
        to: "/",
        text: "Начало",
      },
      {
        to: "/bitovo-klucharski-uslugi",
        text: "Битово-ключарски услуги",
      },
      {
        to: "/avtoklucharski-uslugi",
        text: "Автоключарски услуги",
      },
      {
        to: "/za-nas",
        text: "За нас",
      },
      {
        to: "/kontakti",
        text: "Контакти",
      },
      {
        to: "/obshti-usloviq",
        text: "Общи условия",
      },
      {
        to: "/cookie-policy",
        text: "Политика за бисквитките",
      },
      {
        to: "/lichni-danni",
        text: "Защита на личните данни",
      },
    ],
    FACEBOOK: "https://www.facebook.com/klucharitebg",
  },

  INDEX: {
    META: {
      TITLE: "Ключарски център",
      DESCRIPTION:
        "24/7 Ключарски услуги за гр. София. Денонощен и Авариен ключар. Реагираме до 30 мин. Най-съвременна техника. Обади се на: 0879 881 893",
    },
  },

  ABOUT: {
    META: {
      TITLE: "За нас",
      DESCRIPTION:
        "24/7 Ключарски услуги за гр. София. Денонощен и Авариен ключар. Реагираме до 30 мин. Най-съвременна техника. Обади се на: 0879 881 893",
    },
  },

  CONTACT: {
    META: {
      TITLE: "Контакти",
      DESCRIPTION:
        "24/7 Ключарски услуги за гр. София. Денонощен и Авариен ключар. Реагираме до 30 мин. Най-съвременна техника. Обади се на: 0879 881 893",
    },
  },

  BITOVI: {
    META: {
      TITLE: "Битово-ключарски услуги",
      DESCRIPTION:
        "24/7 Ключарски услуги за гр. София. Денонощен и Авариен ключар. Реагираме до 30 мин. Най-съвременна техника. Обади се на: 0879 881 893",
    },
  },
  AVTO: {
    META: {
      TITLE: "Автоключарски услуги",
      DESCRIPTION:
        "24/7 Ключарски услуги за гр. София. Денонощен и Авариен ключар. Реагираме до 30 мин. Най-съвременна техника. Обади се на: 0879 881 893",
    },
  },
};
